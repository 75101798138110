// Fonts
@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,600");
@import url("https://fonts.googleapis.com/css?family=Lato:300,400,500,600,700&subset=latin-ext");
@import url("https://fonts.googleapis.com/css?family=Roboto+Mono:300,400,500,600,700&subset=latin-ext");

@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';

// Variables
@import "variables";

$enable-responsive-font-sizes: true;
@import '~bootstrap/scss/bootstrap';
@import 'node_modules/bootstrap-vue/src/index.scss';


body {
    font-size: 0.8rem;
}

// // Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
    body {
        font-size: 0.9rem;
    }
}

// // Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    body {
        font-size: 1rem;
    }
}

// Large devices (desktops, 992px and up)
// @media (min-width: 992px) { 
// }

// // Extra large devices (large desktops, 1200px and up)
// @media (min-width: 1200px) { ... }

// Extra Extra large devices (large desktops, 1200px and up)
// @media (min-width: 1400px) {
//  }



.btn {
    font-size: 0.875rem;
    white-space: nowrap;
}

.dropdown-menu {
    font-size: 0.875rem;
}

a {
    color: $primary;
}