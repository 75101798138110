
// Body
$body-bg: #fff;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border-color: $laravel-border-color;

$card-border-color: $laravel-border-color;

// Brands
$primary: #3097D1;
$info: #8eb4cb;
$success: #2ab27b;
$warning: #cbb956;
$danger: #bf5329;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$gray-card: #e8e8e8;
$black: #000;
$white: #fff;

$grid-border: #e7e7e7;
$grid-line-1: #f3f3f3;

// colors: ["#596ea0", "#bf4040"],
$democrat-color: #4F628E; 
$democrat-color-10: lighten($democrat-color, 90%);
$democrat-color-20: lighten($democrat-color, 80%);
$democrat-color-30: lighten($democrat-color, 70%);
$democrat-color-40: lighten($democrat-color, 60%);
$democrat-color-50: lighten($democrat-color, 50%);
$democrat-color-60: lighten($democrat-color, 40%);
$democrat-color-70: lighten($democrat-color, 30%);
$democrat-color-80: lighten($democrat-color, 20%);
$democrat-color-90: lighten($democrat-color, 10%);

$republican-color: #AA3939; 
$republican-color-10: lighten($republican-color, 90%);
$republican-color-20: lighten($republican-color, 80%);
$republican-color-30: lighten($republican-color, 70%);
$republican-color-40: lighten($republican-color, 60%);
$republican-color-50: lighten($republican-color, 50%);
$republican-color-60: lighten($republican-color, 40%);
$republican-color-70: lighten($republican-color, 30%);
$republican-color-80: lighten($republican-color, 20%);
$republican-color-90: lighten($republican-color, 10%);

$predicted-margin: $black;
$win-prob: #808080;
$investment-index: #b8b8b8;

$index-color-1: rgb(113, 173, 113);

// Typography
$font-family-sans-serif: "Raleway", sans-serif;
$font-size-base: 1rem; // 16px
$line-height-base: 1.6;
$text-color: #636b6f;

// Inputs
$input-border-color: lighten($text-color, 40%);
$input-placeholder-color: lighten($text-color, 30%);

$theme-colors: (
    "primary": $primary,
    "info": $info,
    "success": $success,
    "warning": $warning,
    "danger": $danger,
    "gray-100": $gray-100,
    "gray-200": $gray-200,
    "gray-300": $gray-300,
    "gray-400": $gray-400,
    "gray-500": $gray-500,
    "gray-600": $gray-600,
    "gray-700": $gray-700,
    "body": $body-bg,
);

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px,
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1610px
);